<template>
    <div>
      <v-carousel
        cycle
        height="400"
        hide-delimiter-background
        show-arrows-on-hover
        class="mb-5"
      >
        <v-carousel-item
          v-for="(slide, i) in slides"
          :key="i"
          :src="slide.image"
        >
          <v-row
            class="fill-height px-5 text-center"
            align="center"
            justify="center"
          >
            <div :class="`${$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'} white--text font-weight-black`" id="slide">
              {{ slide.title }}
              <v-btn
              color="secondary"
              tile
              class="my-5 d-block mx-auto"
              @click="goTo(slide.link)"
              >
                More Details
              </v-btn>
            </div>
          </v-row>
        </v-carousel-item>
      </v-carousel>
      <trips page="trips" />
    </div>
</template>

<script>
import trips from '../components/trips'
import { homeTrips, headersNoAuth } from '../links'

export default {
  title: '-Trips',
  components: {
    trips
  },
  data () {
    return {
      slides: []
    }
  },
  methods: {
    goTo (trip) {
      if (trip) {
        this.$store.dispatch('setTrip', trip)
        this.$router.push({ name: 'trip' })
      }
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.$http.get(homeTrips, { headers: headersNoAuth() }).then(response => {
      this.slides = response.body.data
    })
  }
}
</script>

<style scoped>
  #slide {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 20px;
    width: 100%;
  }
</style>
