<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" v-for="trip in trips" :key="trip.id" id="trip" class="mb-5">
                <v-card
                class="mx-auto"
                @click="goTo(trip.link)"
                min-height="300"
                >
                    <v-img
                    class="white--text align-end"
                    height="200"
                    :src="trip.image"
                    >
                    </v-img>

                    <v-card-actions>
                      <v-card-title class="body-2 secondary--text font-weight-bold text-center mx-auto"><span style="word-break: break-word;">{{trip.title}}</span></v-card-title>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { homeTrips, trips, headersNoAuth } from '../links'
export default {
  props: ['page'],
  data () {
    return {
      trips: []
    }
  },
  methods: {
    goTo (trip) {
      if (trip) {
        this.$store.dispatch('setTrip', trip)
        this.$router.push({ name: 'trip' })
      }
    }
  },
  created () {
    if (this.page === 'home') {
      if (this.$store.state.homeTrips.length === 0) {
        this.$http.get(homeTrips, { headers: headersNoAuth() }).then(response => {
          this.trips = response.body.data
        })
      } else this.trips = this.$store.state.homeTrips
    } else {
      this.$http.get(trips, { headers: headersNoAuth() }).then(response => {
        this.trips = response.body.data
      })
    }
  }
}
</script>

<style>
    .title {
        background-color: rgba(0, 0, 0, 0.5);
    }
</style>
